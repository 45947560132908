import axios from 'axios';
import { Navigate } from 'react-router-dom';
import React, { Component } from 'react';
import { Store } from 'react-notifications-component';
import Message from 'utils/messages';

// const { token } = store.getState();

// // const token = customization.token;
// console.log('token');
// console.log(token);
// console.log(store.getState().token);
const instance = axios.create({
    // baseURL: 'http://node-env.eba-pakmdcpw.us-east-2.elasticbeanstalk.com/',
    // baseURL: 'http://localhost:4000/v1',
    baseURL: 'https://ostore.rapidbyte.org/v1',
    // baseURL: 'http://localhost:3005',
    // baseURL: "http://192.168.1.24:3005",
    headers: {
        // 'Access-Control-Allow-Origin' : '*',
        // Authorization: `Bearer ${token}`,
        'Content-type': 'application/json'
    }
});

instance.interceptors.request.use(
    (config) => {
        // Do something before request is sent
        const token = localStorage.getItem('token');
        // console.log('token');
        // console.log(token);
        config.headers.Authorization = `Bearer ${token}`;
        return config;
    },
    (error) => {
        // Do something with request error
        console.log(error);
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (response) => {
        // Any status code that lie within the range of 2xx cause this function to trigger
        // Do something with response data
        // console.log(response);
        console.log(response.status);
        return response;
    },
    (error) => {
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        console.log(error);
        console.log(error.response);
        if (error.response.status === 401) {
            let msg = 'Unauthorized User';
            console.log('error');
            Message.addMessage({ title: 'Error was Occured!', msg, type: 'danger' });
            window.location = '/';
        } else if (error.response.status === 404) {
            let msg = 'Cannot find the Server';
            Message.addMessage({ title: 'Error was Occured!', msg, type: 'danger' });
            window.location = '/';
        } else {
            let msg = 'Unknown Error Occured';
            if (error.response !== undefined && error.response.data !== undefined && error.response.data.message !== undefined) {
                msg = error.response.data.message;
            }
            Message.addMessage({ title: 'Error was Occured!', msg, type: 'danger' });
        }
        return Promise.reject(error);
    }
);

export default instance;
