import { useState } from 'react';

export default function ProductImage({ styleDetails, src, placeholder }) {
    const [isLoaded, setIsLoaded] = useState(false);
    const displayNone = {
        display: 'none'
    };
    return (
        <>
            <img
                src={src}
                style={!isLoaded ? displayNone : styleDetails}
                onLoad={() => setIsLoaded(true)}
                onError={() => setIsLoaded(false)}
                alt="Products"
                loading="lazy"
            />
            {!isLoaded && <img style={styleDetails} alt="Products" src={placeholder} />}
        </>
    );
}
