import Lottie from 'react-lottie';
import * as success from 'assets/images/globe.json';

const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: success.default,
    rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
    }
};

const LottieLoader = () => {
    return (
        <div
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                width: '100%'
            }}
        >
            <Lottie options={defaultOptions} height={400} width={400} />
        </div>
    );
};

export default LottieLoader;
