// material-ui
import {
    AppBar,
    Button,
    Dialog,
    Divider,
    Grid,
    IconButton,
    ImageList,
    ImageListItem,
    ImageListItemBar,
    List,
    ListItem,
    ListItemText,
    ListSubheader,
    Slide,
    Step,
    StepLabel,
    Stepper,
    TextField,
    Toolbar,
    Typography
} from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import { Box } from '@mui/system';
import { forwardRef } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { shouldForwardProp } from '@mui/system';
import InfoIcon from '@mui/icons-material/Info';
import Dropzone from 'react-dropzone-uploader';
import { useState } from 'react';
import { useEffect } from 'react';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { Check } from '@mui/icons-material';
import * as React from 'react';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// project imports
import MainCard from 'ui-component/cards/MainCard';
import AnimateButton from 'ui-component/extended/AnimateButton';
import CatogorySelector from 'ui-component/category-selector/CategorySelector';
import HttpCommon from 'utils/http-common';
import messages from 'utils/messages';
import ProductImage from 'ui-component/product-image/ProductImage';
import boxImage from 'assets/images/box.jpg';
import ConfirmationDialog from 'ui-component/confirmation-dialog/ConfirmationDialog';
import POD from './POD';
import ReactToPrint, { useReactToPrint } from 'react-to-print';
import { useRef } from 'react';
import { useReducer } from 'react';
import VerifiedIcon from '@mui/icons-material/Verified';
import PayHereLogo from '../../../../assets/images/payhere.png';

// ==============================|| View Order PAGE ||============================== //

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const StyledButton = styled(Button, { shouldForwardProp })(({ theme }) => ({
    // cursor: 'pointer',
    // padding: 16,
    '&:hover': {
        // background: theme.palette.primary
        backgroundColor: '#fff',
        color: '#3c52b2'
    }
}));

// specify upload params and url for your files
const getUploadParams = ({ meta }) => {
    return { url: ' ' };
};

const imgBaseUrl = 'https://starfish-app-vil5p.ondigitalocean.app/uploads/';

const steps = ['Unpaid', 'Processing', 'Shipped', 'Completed', 'Return'];

const CustomIcon = ({ active, completed, icon }) => {
    const contents = completed ? <Check fontSize="inherit" /> : icon;
    return (
        <div
            style={{
                backgroundColor: completed ? 'green' : 'gray',
                color: 'white',
                minHeight: '30px',
                minWidth: '30px',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                paddingRight: '1px',
                paddingTop: '0px',
                marginTop: '-3px',
                fontSize: '14px'
            }}
        >
            {contents}
        </div>
    );
};

export default function ViewOrderPage({ open, setOpen, rowDetails, onEnd }) {
    const [_, forceUpdate] = useReducer((x) => x + 1, 0);
    const [categoryOpen, setCategoryOpen] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    const [stepType, setStepType] = useState('next');
    const [openConfirm, setOpenConfirm] = useState(false);
    const [details, setDetails] = useState(undefined);
    const [values, setValues] = useState({
        postcode: '',
        address: '',
        city: ''
    });
    const componentRef = useRef(null);
    const [opentrack, setTrackOpen] = React.useState(false);

    const handleClickTrackOpen = () => {
        setTrackOpen(true);
    };

    const handleTrackClose = () => {
        setTrackOpen(false);
    };

    const handleChange = (prop) => (event) => {
        setValues({ ...values, [prop]: event.target.value });
    };

    const handleClickClose = () => {
        setCategoryOpen(false);
    };

    const handleClickConfirmOpen = () => {
        setOpenConfirm(true);
    };

    const handleClickConfirmClose = () => {
        handleStep();
        setOpenConfirm(false);
    };

    const handleBack = () => {
        setStepType('back');
        handleClickConfirmOpen();
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const totalSteps = () => {
        return steps.length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const handleStep = () => {
        console.log(stepType);
        if (stepType === 'next') {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleNext = () => {
        setStepType('next');
        const newActiveStep = activeStep + 1;
        // const newActiveStep = isLastStep() && !allStepsCompleted() ? activeStep : activeStep + 1;
        handleClickConfirmOpen();
        setActiveStep(newActiveStep);
    };

    const handleInitStatus = (step) => {
        switch (step) {
            case 'unpaid':
                setActiveStep(1);
                break;
            case 'processing':
                setActiveStep(2);
                break;
            case 'shipped':
                setActiveStep(3);
                break;
            case 'completed':
                setActiveStep(4);
                break;
            case 'return':
                setActiveStep(5);
                break;
            default:
                setActiveStep(0);
                break;
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });

    const handleReferrerDetails = (rowDetails) => {
        HttpCommon.get(`/api/user/${rowDetails.refId}`)
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    rowDetails.refeerer = response.data.data[0];
                    setDetails(rowDetails);
                }
                console.log(details);
            })
            .catch((err) => {
                console.log(err);
                // setOpen(false);
            });
    };

    const handleUpdateAddress = () => {
        // console.log(files.map((f) => f.meta));
        const key = localStorage.getItem('userID');
        const data = {
            id: details.id,
            postcode: values.postcode,
            address: values.address,
            city: values.city
        };

        HttpCommon.put(`/api/order`, data)
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Updated!', msg: 'Order Shipping Details Update Succesfully', type: 'success' });
                }
                details.postcode = values.postcode;
                details.address = values.address;
                details.city = values.city;
                setDetails(details);
                onEnd();
                setIsEditable(false);
                // setOpen(false);
            })
            .catch((err) => {
                console.log(err);
                // setOpen(false);
            });
    };

    const handleUpdateStatus = () => {
        // console.log(files.map((f) => f.meta));
        const key = localStorage.getItem('userID');
        const data = {
            id: details.id,
            status: details.status
        };
        switch (activeStep) {
            case 1:
                data.status = 'unpaid';
                break;
            case 2:
                data.status = 'processing';
                break;
            case 3:
                data.status = 'shipped';
                break;
            case 4:
                data.status = 'completed';
                break;
            case 5:
                data.status = 'return';
                break;
            default:
                data.status = details.status;
                break;
        }
        HttpCommon.put(`/api/order/status`, data)
            .then((response) => {
                console.log(response.data.data);
                if (response.data.success) {
                    messages.addMessage({ title: 'Updated!', msg: 'Order Status Updated Succesfully', type: 'success' });
                }
                onEnd();
                setOpenConfirm(false);
            })
            .catch((err) => {
                console.log(err);
                setOpenConfirm(false);
            });
    };

    useEffect(() => {
        console.log(rowDetails);
        console.log(rowDetails.status);
        if (rowDetails !== undefined) {
            setValues({
                postcode: rowDetails.postcode,
                address: rowDetails.address,
                city: rowDetails.city
            });
            // setDetails(rowDetails);
            handleInitStatus(rowDetails.status);
            handleReferrerDetails(rowDetails);
        }
    }, []);

    return (
        details !== undefined && (
            <>
                <AppBar sx={{ position: 'relative' }} color="secondary">
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpen(false)} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="subtitle1" component="div">
                            View Order
                        </Typography>
                        <StyledButton color="inherit" onClick={handleClose}>
                            close
                        </StyledButton>
                    </Toolbar>
                </AppBar>
                <Grid container justifyContent="center" padding={3} spacing={2}>
                    <Grid item justifyContent="center" alignItems="center" xs={12} lg={12}>
                        <MainCard elevation={2} title="Status" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)">
                            <Box sx={{ width: '100%' }}>
                                <Stepper activeStep={activeStep} color="secondary" alternativeLabel>
                                    {steps.map((label) => (
                                        <Step key={label}>
                                            <StepLabel StepIconComponent={CustomIcon}>{label}</StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </Box>
                            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                <Button color="secondary" disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
                                    Back
                                </Button>
                                <Box sx={{ flex: '1 1 auto' }} />
                                <Button onClick={handleNext} color="secondary" disabled={activeStep === steps.length} sx={{ mr: 1 }}>
                                    Next
                                </Button>
                            </Box>
                        </MainCard>
                    </Grid>
                    {/* <Grid container justifyContent="center" alignItems="center" xs={12} lg={8} pt={2} pl={2} spacing={0}> */}
                    <Grid item justifyContent="center" alignItems="center" xs={12} lg={8}>
                        <MainCard
                            elevation={2}
                            title="Shipping"
                            boxShadow
                            shadow="0 2px 5px 2px rgba(0,0,0,0.15)"
                            secondary={
                                !isEditable ? (
                                    <AnimateButton>
                                        <Button
                                            fullWidth
                                            size="large"
                                            type="submit"
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => setIsEditable(true)}
                                            sx={{ borderRadius: 20 }}
                                        >
                                            Edit
                                        </Button>
                                    </AnimateButton>
                                ) : (
                                    <div style={{ display: 'flex' }}>
                                        <AnimateButton>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                color="secondary"
                                                onClick={() => setIsEditable(false)}
                                                sx={{ borderRadius: 20 }}
                                            >
                                                Close
                                            </Button>
                                        </AnimateButton>
                                        <div style={{ width: 20 }} />
                                        <AnimateButton>
                                            <Button
                                                fullWidth
                                                size="large"
                                                type="submit"
                                                variant="contained"
                                                color="secondary"
                                                onClick={handleUpdateAddress}
                                                sx={{ borderRadius: 20 }}
                                            >
                                                Save
                                            </Button>
                                        </AnimateButton>
                                    </div>
                                )
                            }
                        >
                            {isEditable ? (
                                <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                    <Grid item justifyContent="center" alignItems="center" xs={12} sm={4}>
                                        <TextField
                                            id="outlined-error"
                                            fullWidth
                                            label="Address"
                                            value={values.address}
                                            onChange={handleChange('address')}
                                        />
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={12} sm={4}>
                                        <TextField
                                            id="outlined-error"
                                            fullWidth
                                            label="City"
                                            value={values.city}
                                            onChange={handleChange('city')}
                                        />
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={12} sm={4}>
                                        <TextField
                                            id="outlined-error"
                                            fullWidth
                                            label="PostCode"
                                            value={values.postcode}
                                            onChange={handleChange('postcode')}
                                        />
                                    </Grid>
                                </Grid>
                            ) : (
                                <Grid container justifyContent="center" alignItems="start" spacing={3}>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Shipping Address
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14}>
                                            {details.firstName} {details.lastName}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14}>
                                            {values.address}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14}>
                                            {values.city}
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {values.postcode}
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            MA Number
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.userId}
                                        </Typography>
                                    </Grid>
                                    <Grid item justifyContent="center" alignItems="center" xs={6}>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Mobile No 1
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={2}>
                                            {details.phone}
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Mobile No 2
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={2}>
                                            {details.mobile}
                                        </Typography>
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Email
                                        </Typography>
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.email}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            )}
                        </MainCard>
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" xs={12} lg={4}>
                        <MainCard
                            elevation={2}
                            title="Order"
                            boxShadow
                            shadow="0 2px 5px 2px rgba(0,0,0,0.15)"
                            secondary={
                                <AnimateButton>
                                    <Button
                                        fullWidth
                                        size="large"
                                        type="submit"
                                        variant="contained"
                                        color="secondary"
                                        onClick={handlePrint}
                                        sx={{ borderRadius: 20 }}
                                    >
                                        Print POD
                                    </Button>
                                </AnimateButton>
                            }
                        >
                            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                <Grid item justifyContent="center" alignItems="center" xs={6}>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Order ID
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Order Date
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Buyer
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Order Amount
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Shipping Fee
                                    </Typography>
                                    {details.registrationFee && (
                                        <Typography variant="h6" fontSize={14} mb={1}>
                                            Registration Fee
                                        </Typography>
                                    )}
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Tracking No
                                    </Typography>
                                </Grid>
                                <Grid item justifyContent="center" alignItems="center" xs={6}>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.id}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.orderedDate.substring(0, 10)}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.firstName} {details.lastName}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.orderAmount}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.shippingFee}
                                    </Typography>
                                    {details.registrationFee && (
                                        <Typography variant="subtitle2" fontSize={14} mb={1}>
                                            {details.registrationFee}
                                        </Typography>
                                    )}
                                    <Typography variant="h6" fontSize={14}>
                                        {details.trackingNo ? (
                                            <Grid container spacing={2}>
                                                <Grid item xs={8}>
                                                    <Typography variant="h6" fontSize={14} mb={1}>
                                                        {details.trackingNo}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <AnimateButton>
                                                        <Button
                                                            size="medium"
                                                            type="submit"
                                                            variant="contained"
                                                            color="warning"
                                                            onClick={handleClickTrackOpen}
                                                            sx={{ borderRadius: 20 }}
                                                        >
                                                            Update
                                                        </Button>
                                                    </AnimateButton>
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            <AnimateButton>
                                                <Button
                                                    size="medium"
                                                    type="submit"
                                                    variant="contained"
                                                    color="warning"
                                                    onClick={handleClickTrackOpen}
                                                    sx={{ borderRadius: 20 }}
                                                >
                                                    Add Tracking No
                                                </Button>
                                            </AnimateButton>
                                        )}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Grid>
                    <Grid item justifyContent="center" alignItems="center" xs={12} lg={8}>
                        <MainCard elevation={2} title="Items" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)">
                            {details.cart.map((item) => (
                                <>
                                    <Grid container justifyContent="center">
                                        <Grid item justifyContent="center" alignItems="center" xs={12} sm={2}>
                                            <ProductImage
                                                styleDetails={{ width: 100, borderRadius: 5, zIndex: 2 }}
                                                src={imgBaseUrl + item.img[0] ? item.img[0] : '1681916826113-175080027.jpg'}
                                                placeholder={boxImage}
                                            />
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" xs={12} sm={4}>
                                            <Typography variant="h6" fontSize={14} mb={1}>
                                                {item.title}
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={12} mb={1}>
                                                Item ID: {item.id}
                                            </Typography>
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" xs={12} sm={2}>
                                            <Typography variant="h6" fontSize={14} mb={1}>
                                                Quantity
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={12} mb={1}>
                                                {item.qty}
                                            </Typography>
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" xs={12} sm={2}>
                                            <Typography variant="h6" fontSize={14} mb={1}>
                                                Price
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={12} mb={1}>
                                                LKR {item.price}
                                            </Typography>
                                        </Grid>
                                        <Grid item justifyContent="center" alignItems="center" xs={12} sm={2}>
                                            <Typography variant="h6" fontSize={14} mb={1}>
                                                Total
                                            </Typography>
                                            <Typography variant="subtitle2" fontSize={12} mb={1}>
                                                LKR {parseInt(item.qty) * parseInt(item.price)}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Divider sx={{ mb: 2 }} />
                                </>
                            ))}
                        </MainCard>
                    </Grid>
                    {/* </Grid> */}
                    <Grid item justifyContent="center" alignItems="center" xs={12} lg={4}>
                        <MainCard elevation={2} title="Payment" boxShadow shadow="0 2px 5px 2px rgba(0,0,0,0.15)">
                            <Grid container justifyContent="center" alignItems="center" spacing={3}>
                                <Grid item justifyContent="center" alignItems="center" xs={6}>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Payment Type
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Paid Date
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Total Amount
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Referer ID
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Direct Commision
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Upliner ID
                                    </Typography>
                                    <Typography variant="h6" fontSize={14} mb={1}>
                                        Indirect Commision
                                    </Typography>
                                </Grid>
                                <Grid item justifyContent="center" alignItems="center" xs={6}>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.paymentType && details.isPayhereVerified ? (
                                            <Grid container spacing={2} alignItems={'center'}>
                                                <Grid item xs={4}>
                                                    <img
                                                        src={PayHereLogo}
                                                        alt=""
                                                        style={{
                                                            width: '60px',
                                                            textAlign: 'center',
                                                            background: '#fff'
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item xs={4}>
                                                    <VerifiedIcon color="#00fff" />
                                                </Grid>
                                            </Grid>
                                        ) : (
                                            details.paymentType
                                        )}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.paidDate ? details.paidDate.substring(0, 10) : 'Unavailable'}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.totalAmount}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.refId}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.totalDirectCommision}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.uplinerId}
                                    </Typography>
                                    <Typography variant="subtitle2" fontSize={14} mb={1}>
                                        {details.totalIndirectCommision}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </MainCard>
                    </Grid>
                    {/* <Grid item justifyContent="center" alignItems="center" xs={12} lg={8}>
                    <MainCard elevation={2} title="Items"></MainCard>
                </Grid> */}
                </Grid>
                <div style={{ display: 'none' }}>
                    <div ref={componentRef}>
                        <POD data={details} />
                    </div>
                </div>
                <Dialog
                    scroll="paper"
                    fullWidth
                    maxWidth="sm"
                    open={openConfirm}
                    onClose={handleClickConfirmClose}
                    TransitionComponent={Transition}
                >
                    <ConfirmationDialog
                        setOpen={setOpenConfirm}
                        Title="Are you sure ?"
                        Description="You are trying to update the status of a order. Are you want to update this?"
                        onAgree={handleUpdateStatus}
                        onClose={handleClickConfirmClose}
                    />
                </Dialog>
                <Dialog
                    open={opentrack}
                    onClose={handleTrackClose}
                    PaperProps={{
                        component: 'form',
                        onSubmit: (event) => {
                            event.preventDefault();
                            const formData = new FormData(event.currentTarget);
                            const formJson = Object.fromEntries(formData.entries());
                            const trackno = formJson.trackingNo;
                            if (trackno) {
                                HttpCommon.put(`/api/order`, { id: details.id, trackingNo: trackno, status: 'shipped' })
                                    .then((response) => {
                                        console.log(response.data.data);
                                        if (response.data.success) {
                                            messages.addMessage({
                                                title: 'Updated!',
                                                msg: 'Order Shipping Details Update Succesfully',
                                                type: 'success'
                                            });
                                        }
                                        details.trackingNo = trackno;
                                        console.log(details);
                                        setDetails(details);
                                        handleInitStatus('shipped');
                                        forceUpdate();
                                    })
                                    .catch((err) => {
                                        console.log(err);
                                    });
                            }
                            handleTrackClose();
                        }
                    }}
                >
                    <DialogTitle>
                        <h3>Update Tracking</h3>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter tracking details bellow.
                            <br /> Note: Order status will be updated to Shipped.
                        </DialogContentText>
                        <TextField
                            required
                            margin="dense"
                            id="trackingNo"
                            name="trackingNo"
                            label="Tracking No"
                            type="text"
                            fullWidth
                            variant="standard"
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button variant="contained" color="warning" onClick={handleTrackClose}>
                            Cancel
                        </Button>
                        <Button variant="contained" color="info" type="submit">
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    );
}
